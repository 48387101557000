import React from "react";

import {
  Authenticator,
  buildCollection,
  buildProperty,
  buildSchema,
  EntityReference,
  FirebaseCMSApp,
  NavigationBuilder,
  NavigationBuilderProps
} from "@camberi/firecms";

import "typeface-rubik";
import "typeface-space-mono";
import internal from "stream";

const firebaseConfig = {
  apiKey: "AIzaSyAESXooEfeCjh2QqyLrWmZziG5O_3NHTpg",
  authDomain: "moijoy-10151.firebaseapp.com",
  projectId: "moijoy-10151",
  storageBucket: "moijoy-10151.appspot.com",
  messagingSenderId: "1001707392864",
  appId: "1:1001707392864:web:11c2f2f88919dbb793d805",
  measurementId: "G-99PWJLHDZJ"
};

type user = {
  display_name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  photo_url: string;
  thumbnail: string;
  logo: string;
  bio_description: string;
  bio_long: string;
  one_sentence: string;
  featured: boolean;
  active_creator: boolean;
  published_creator: boolean;
  coming_soon: boolean;
  show_programs: boolean;
  show_collaborators: boolean;
  liked_by: EntityReference[];
  categories: EntityReference[];
  followers: EntityReference[];
  created_time: Date;
}

type category = {
  display_name: string;
  description: string;
  image: string;
  thumbnail: string;
  active: boolean;
  show_on_homepage: boolean;
  show_creators: boolean;
  show_programs: boolean;
  show_sessions: boolean;
  followers: EntityReference[];
}

type session = {
  title: string;
  played: number;
  notes: string;
  image: string;
  thumbnail: string;
  duration: number;
  public_visibility: boolean;
  interactive: boolean;
  published: boolean;
  featured: boolean;
  user: EntityReference;
  language: EntityReference;
  categories: EntityReference[];
  liked_by: EntityReference[];
  cards: EntityReference[];
  collaborator: EntityReference;
  show_collaborator: boolean;
  extra_material: string;
  created_on: Date;
}

type card = {
  title: string;
  audio: string;
  card_notes: string
  show_card_notes: boolean;
  interactive: boolean;
  user: EntityReference;
  link_1_url: string;
  link_1_name: string;
  link_2_url: string;
  link_2_name: string;
  background_img: string;
}

type collaborator = {
  display_name: string;
  bio_description: string;
  bio_long: string;
  image: string;
  thumbnail: string;
  show_linked_account: boolean;
  linked_user_account: EntityReference;
  user: EntityReference;
  one_sentence: string;
}

type faq = {
  question: string;
  answer: string;
}

type review = {
  user: EntityReference;
  description: string;
  rating: number;
public: boolean;
}

type language = {
  name: string;
  code: string;
}

type program = {
  title: string;
  learning_description: string;
  description: string;
  cta_title: string;
  cta_description: string;
  image: string;
  thumbnail: string;
  cta_image: string;
  price: number;
  free: boolean;
  days: number;
  public_visibility: boolean;
  interactive: boolean;
  published: boolean;
  user: EntityReference;
  users: EntityReference[];
  language: EntityReference;
  categories: EntityReference[];
  reviews: EntityReference[];
  faqs: EntityReference[];
  liked_by: EntityReference[];
  members: EntityReference[];
  sessions: EntityReference[];
  collaborator: EntityReference;
  show_collaborator: boolean;
  show_multiple_users: boolean;
  viewed_by: EntityReference[];
  payment_id: string;
  created_on: Date;
}

const userSchema = buildSchema < user > ({
  name: "user",
  properties: {
    display_name: {
      title: "Name",
      validation: {
        required: true
      },
      dataType: "string"
    },
    first_name: {
      title: "First name",
      validation: {
        required: true
      },
      dataType: "string"
    },
    last_name: {
      title: "Last name",
      validation: {
        required: true
      },
      dataType: "string"
    },
    email: {
      title: "Email",
      validation: {
        required: true
      },
      dataType: "string"
    },
    phone_number: {
      title: "Phone number",
      validation: {
        required: false
      },
      dataType: "string"
    },
    one_sentence: {
      title: "One sentence",
      validation: {
        required: true
      },
      dataType: "string"
    },
    featured: ({
      values
    }) => buildProperty({
      title: "featured",
      dataType: "boolean",
      columnWidth: 100,
    }),
    active_creator: ({
      values
    }) => buildProperty({
      title: "Active creator",
      dataType: "boolean",
      columnWidth: 100,
    }),
    published_creator: ({
      values
    }) => buildProperty({
      title: "Publish creator on platform",
      dataType: "boolean",
      columnWidth: 100,
    }),
    coming_soon: ({
      values
    }) => buildProperty({
      title: "Indicate that creator is coming soon (published should be TRUE)",
      dataType: "boolean",
      columnWidth: 100,
    }),
    show_programs: ({
      values
    }) => buildProperty({
      title: "Show programs",
      dataType: "boolean",
      columnWidth: 100,
    }),
    liked_by: {
      dataType: "array",
      title: "Liked by",
      description: "Users that like current user",
      of: {
        dataType: "reference",
        path: "users"
      }
    },
    categories: {
      dataType: "array",
      title: "Categories",
      description: "Reference to self",
      of: {
        dataType: "reference",
        path: "categories"
      }
    },
    followers: {
      dataType: "array",
      title: "Followers",
      description: "Users following this creator",
      of: {
        dataType: "reference",
        path: "users"
      }
    },
    photo_url: buildProperty({
      title: "Profile Image",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images",
          acceptedFiles: ["image/*"],
          storeUrl: true,
        }
      }
    }),
    thumbnail: buildProperty({
      title: "Thumbnail - small profile image (40 x 40)",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images",
          acceptedFiles: ["image/*"],
          storeUrl: true,
        }
      }
    }),
    logo: buildProperty({
      title: "Logo",
      description: "Upload a square image. Image is shown in 30 x 30 pixels but exports should be higher quality.",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images",
          acceptedFiles: ["image/*"],
          storeUrl: true,
        }
      }
    }),
    bio_description: {
      title: "Bio",
      description: "Not mandatory but it'd be awesome if you filled this up",
      longDescription: "Example of a long description hidden under a tooltip. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis bibendum turpis. Sed scelerisque ligula nec nisi pellentesque, eget viverra lorem facilisis. Praesent a lectus ac ipsum tincidunt posuere vitae non risus. In eu feugiat massa. Sed eu est non velit facilisis facilisis vitae eget ante. Nunc ut malesuada erat. Nullam sagittis bibendum porta. Maecenas vitae interdum sapien, ut aliquet risus. Donec aliquet, turpis finibus aliquet bibendum, tellus dui porttitor quam, quis pellentesque tellus libero non urna. Vestibulum maximus pharetra congue. Suspendisse aliquam congue quam, sed bibendum turpis. Aliquam eu enim ligula. Nam vel magna ut urna cursus sagittis. Suspendisse a nisi ac justo ornare tempor vel eu eros.",
      dataType: "string",
      columnWidth: 300
    },
    bio_long: {
      title: "Bio long (about tab)",
      description: "Not mandatory but it'd be awesome if you filled this up",
      longDescription: "Example of a long description hidden under a tooltip. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis bibendum turpis. Sed scelerisque ligula nec nisi pellentesque, eget viverra lorem facilisis. Praesent a lectus ac ipsum tincidunt posuere vitae non risus. In eu feugiat massa. Sed eu est non velit facilisis facilisis vitae eget ante. Nunc ut malesuada erat. Nullam sagittis bibendum porta. Maecenas vitae interdum sapien, ut aliquet risus. Donec aliquet, turpis finibus aliquet bibendum, tellus dui porttitor quam, quis pellentesque tellus libero non urna. Vestibulum maximus pharetra congue. Suspendisse aliquam congue quam, sed bibendum turpis. Aliquam eu enim ligula. Nam vel magna ut urna cursus sagittis. Suspendisse a nisi ac justo ornare tempor vel eu eros.",
      dataType: "string",
      columnWidth: 300
    },
    show_collaborators: ({
      values
    }) => buildProperty({
      title: "Show collaborators",
      description: "Set to TRUE if you want to show collaborators in the about of the creator profile",
      dataType: "boolean",
      columnWidth: 100,
      validation: {
        required: true
      },
    }),
    created_time: {
      title: "Created time",
      dataType: "timestamp"
    }
  }
});

const categorySchema = buildSchema < category > ({
  name: "category",
  properties: {
    display_name: {
      title: "Name",
      validation: {
        required: true
      },
      dataType: "string"
    },
    description: {
      title: "Description",
      validation: {
        required: true
      },
      dataType: "string"
    },
    active: ({
      values
    }) => buildProperty({
      title: "Active creator",
      dataType: "boolean",
      columnWidth: 100,
    }),
    show_on_homepage: ({
      values
    }) => buildProperty({
      title: "Show on homepage?",
      dataType: "boolean",
      columnWidth: 100,
    }),
    show_creators: ({
      values
    }) => buildProperty({
      title: "Show creators in category page",
      dataType: "boolean",
      columnWidth: 100,
    }),
    show_programs: ({
      values
    }) => buildProperty({
      title: "Show programs in category page",
      dataType: "boolean",
      columnWidth: 100,
    }),
    show_sessions: ({
      values
    }) => buildProperty({
      title: "Show sessions in category page",
      dataType: "boolean",
      columnWidth: 100,
    }),
    image: buildProperty({ 
      title: "Cover image",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images",
          acceptedFiles: ["image/*"],
          storeUrl: true,
        }
      }
    }),
    thumbnail: buildProperty({ 
      title: "Thumbnail Image",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images",
          acceptedFiles: ["image/*"],
          storeUrl: true,
        }
      }
    }),
    followers: {
      dataType: "array",
      title: "Followers",
      description: "Users following this category",
      of: {
        dataType: "reference",
        path: "users"
      }
    },
  }
});

const collaboratorSchema = buildSchema < collaborator > ({
  name: "collaborator",
  properties: {
    display_name: {
      title: "Name",
      validation: {
        required: true
      },
      dataType: "string"
    },
    one_sentence: {
      title: "One sentence",
      validation: {
        required: true
      },
      dataType: "string"
    },
    bio_description: {
      title: "Short bio",
      validation: {
        required: true
      },
      dataType: "string"
    },
    bio_long: {
      title: "Bio description long",
      validation: {
        required: true
      },
      dataType: "string"
    },
    image: buildProperty({ 
      title: "Collaborator profile image",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images",
          acceptedFiles: ["image/*"],
          storeUrl: true,
        }
      }
    }),
    thumbnail: buildProperty({
      title: "Thumbnail - small profile image (40 x 40)",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images",
          acceptedFiles: ["image/*"],
          storeUrl: true,
        }
      }
    }),
    linked_user_account: {
      dataType: "reference",
      title: "Linked user account (if applicable)",
      description: "Link to the account of the creator, if no collaborator is used, use the account of the creator - it must be filled",
      path: "users",
      validation: {
        required: true
      },
    },
    show_linked_account: ({
      values
    }) => buildProperty({
      title: "Show linked account",
      dataType: "boolean",
      columnWidth: 100,
      validation: {
        required: true
      },
    }),
    user: {
      dataType: "reference",
      title: "User",
      description: "Who is the creator of this collaborator?",
      path: "users",
      validation: {
        required: true
      },
    },
  }
});

const faqSchema = buildSchema < faq > ({
  name: "faq",
  properties: {
    question: {
      title: "Name",
      validation: {
        required: true
      },
      dataType: "string"
    },
    answer: {
      title: "Description",
      validation: {
        required: true
      },
      dataType: "string"
    }
  }
});

const reviewSchema = buildSchema < review > ({
  name: "review",
  properties: {
    user: {
      dataType: "reference",
      title: "User",
      description: "Reference to self",
      path: "users"
    },
    description: {
      title: "Description",
      validation: {
        required: true
      },
      dataType: "string"
    },
    public: ({
      values
    }) => buildProperty({
      title: "Show review on program",
      dataType: "boolean",
      columnWidth: 100,
    }),
    rating: {
      title: "Rating integer",
      validation: {
          required: true,
          requiredMessage: "Rating",
          min: 0,
          max: 100
      },
      description: "Rating",
      dataType: "number"
  },

  }
});

const languageSchema = buildSchema < language > ({
  name: "language",
  properties: {
    name: {
      title: "Description",
      validation: {
        required: true
      },
      dataType: "string"
    },
    code: {
      title: "Description",
      validation: {
        required: true
      },
      dataType: "string"
    }
  }
});

const programSchema = buildSchema < program > ({
  name: "program",
  properties: {
    title: {
      title: "Title",
      validation: {
        required: true
      },
      dataType: "string"
    },
    payment_id: {
      title: "Payment id (app stores)",
      validation: {
        required: true
      },
      dataType: "string"
    },
    user: {
      dataType: "reference",
      title: "User",
      description: "Reference to self",
      path: "users",
    },

  price: {
    title: "Price",
    validation: {
        required: true,
        requiredMessage: "What price are you charging?",
        min: 0,
        max: 500
    },
    description: "Euros",
    dataType: "number"
},
free: ({
  values
}) => buildProperty({
  title: "Free",
  dataType: "boolean",
  columnWidth: 100,
}),
    learning_description: {
      title: "Learning description - what are the benefits?",
      validation: {
        required: true
      },
      dataType: "string"
    },
    users: {
      dataType: "array",
      title: "User(s)",
      description: "Users that co-own the program (max 2 users allowed)",
      of: {
        dataType: "reference",
        path: "users"
      }
    },
  description: {
    title: "Program description",
    validation: {
      required: true
    },
    dataType: "string"
  },
  cta_title: {
    title: "CTA title",
    validation: {
      required: true
    },
    dataType: "string"
  },
  cta_description: {
    title: "CTA description",
    validation: {
      required: true
    },
    dataType: "string"
  },
  days: {
      title: "Number of days",
      validation: {
          required: true,
          requiredMessage: "Set the number of days",
          min: 0,
          max: 100
      },
      description: "Minutes",
      dataType: "number"
  },
  image: buildProperty({
    title: "Cover image",
    dataType: "string",
    config: {
      storageMeta: {
        mediaType: "image",
        storagePath: "images",
        acceptedFiles: ["image/*"],
        storeUrl: true,
      }
    }
  }),
  thumbnail: buildProperty({ 
    title: "Program thumbnail Image",
    dataType: "string",
    config: {
      storageMeta: {
        mediaType: "image",
        storagePath: "images",
        acceptedFiles: ["image/*"],
        storeUrl: true,
      }
    }
  }),
  cta_image: buildProperty({ 
    title: "CTA Image",
    dataType: "string",
    config: {
      storageMeta: {
        mediaType: "image",
        storagePath: "images",
        acceptedFiles: ["image/*"],
        storeUrl: true,
      }
    }
  }),
  public_visibility: ({
    values
  }) => buildProperty({
    title: "Show on profile for free",
    dataType: "boolean",
    columnWidth: 100,
  }),
  interactive: ({
    values
  }) => buildProperty({
    title: "Interactive",
    dataType: "boolean",
    columnWidth: 100,
  }),
  published: ({
    values
  }) => buildProperty({
    title: "Published",
    dataType: "boolean",
    columnWidth: 100,
  }),
  language: {
    dataType: "reference",
    title: "Language",
    description: "What language is the session in?",
    path: "languages"
  },
  categories: {
    dataType: "array",
    title: "Categories",
    description: "What categories does this program fall in?",
    of: {
      dataType: "reference",
      path: "categories"
    }
  },
  faqs: {
    dataType: "array",
    title: "FAQs",
    description: "What FAQs should be listed here?",
    of: {
      dataType: "reference",
      path: "faqs"
    }
  },
  reviews: {
    dataType: "array",
    title: "Reviews",
    description: "What reviews should be listed here? Preferably 2",
    of: {
      dataType: "reference",
      path: "reviews"
    }
  },
  liked_by: {
    dataType: "array",
    title: "Liked by",
    description: "Users that like this session",
    of: {
      dataType: "reference",
      path: "users"
    }
  },
  viewed_by: {
    dataType: "array",
    title: "Viewed by",
    description: "Users that viewed this creator",
    of: {
      dataType: "reference",
      path: "users"
    }
  },
  members: {
    dataType: "array",
    title: "Members",
    description: "Users that are a member of this program",
    of: {
      dataType: "reference",
      path: "users"
    }
  },
  sessions: {
    dataType: "array",
    title: "Sessions",
    description: "Sessions of the program (in order)",
    of: {
      dataType: "reference",
      path: "sessions"
    }
  },
  collaborator: {
    dataType: "reference",
    title: "Collaborator",
    description: "Do you want to show a collaborator? - should always be filled in, if none - choose template",
    path: "collaborators",
    validation: {
      required: true
    },
  },
  show_collaborator: ({
    values
  }) => buildProperty({
    title: "Show collaborator",
    description: "Set to false if no collaborator is chosen, set to TRUE only if show multiple users is FALSE",
    dataType: "boolean",
    columnWidth: 100,
    validation: {
      required: true
    },
  }),
  show_multiple_users: ({
    values
  }) => buildProperty({
    title: "Show multiple users",
    description: "Set to TRUE only if show collaborator is FALSE",
    dataType: "boolean",
    columnWidth: 100,
    validation: {
      required: true
    },
  }),
  created_on: {
    title: "Created time",
    dataType: "timestamp"
  }
} 
});

const sessionSchema = buildSchema < session > ({
  name: "session",
  properties: {
    title: {
      title: "Title",
      validation: {
        required: true
      },
      dataType: "string"
    },
    played: {
      title: "Played",
      description: "Number of times",
      dataType: "number",
      disabled: true
  },
    user: {
      dataType: "reference",
      title: "User",
      description: "Reference to self",
      path: "users"
    },
  notes: {
    title: "Session notes",
    validation: {
      required: true
    },
    dataType: "string"
  },
  duration: {
      title: "Duration",
      validation: {
          required: true,
          requiredMessage: "Set the duration of the session (this includes time to do exercises)",
          // min: 0,
          // max: 60
      },
      description: "Minutes",
      dataType: "number"
  },
  extra_material: {
      title: "Link to extra document (displayed in notes)",
      description: "Link to document",
      dataType: "string"
  },
  image: buildProperty({ 
    title: "Featured image (bigger size)",
    dataType: "string",
    config: {
      storageMeta: {
        mediaType: "image",
        storagePath: "images",
        acceptedFiles: ["image/*"],
        storeUrl: true,
      }
    }
  }),
  thumbnail: buildProperty({ 
    title: "Thumbnail Image",
    dataType: "string",
    config: {
      storageMeta: {
        mediaType: "image",
        storagePath: "images",
        acceptedFiles: ["image/*"],
        storeUrl: true,
      }
    }
  }),
  public_visibility: ({
    values
  }) => buildProperty({
    title: "Show on profile for free",
    dataType: "boolean",
    columnWidth: 100,
  }),
  interactive: ({
    values
  }) => buildProperty({
    title: "Interactive",
    dataType: "boolean",
    columnWidth: 100,
  }),
  published: ({
    values
  }) => buildProperty({
    title: "Published",
    dataType: "boolean",
    columnWidth: 100,
  }),
  featured: ({
    values
  }) => buildProperty({
    title: "Featured",
    dataType: "boolean",
    columnWidth: 100,
  }),
  language: {
    dataType: "reference",
    title: "Language",
    description: "What language is the session in?",
    path: "languages"
  },
  categories: {
    dataType: "array",
    title: "Categories",
    description: "Reference to self",
    of: {
      dataType: "reference",
      path: "categories"
    }
  },
  liked_by: {
    dataType: "array",
    title: "Liked by",
    description: "Users that like this session",
    of: {
      dataType: "reference",
      path: "users"
    }
  },
  cards: {
    dataType: "array",
    title: "Cards",
    description: "Cards of the session (in order)",
    of: {
      dataType: "reference",
      path: "cards"
    }
  },
  collaborator: {
    dataType: "reference",
    title: "Collaborator",
    description: "Do you want to show a collaborator? - if no one, choose template - one must be selected",
    path: "collaborators",
    validation: {
      required: true
    },
  },
  show_collaborator: ({
    values
  }) => buildProperty({
    title: "Show collaborator",
    dataType: "boolean",
    columnWidth: 100,
    validation: {
      required: true
    },
  }),
  created_on: {
    title: "Created time",
    dataType: "timestamp"
  }
} 
});


const cardSchema = buildSchema < card > ({
  name: "card",
  properties: {
    title: {
      title: "Name",
      validation: {
        required: true
      },
      dataType: "string"
    },
    card_notes: {
      title: "Card notes",
      validation: {
        required: false
      },
      dataType: "string"
    },
    link_1_url: {
      title: "Link 1 url",
      validation: {
        required: false
      },
      dataType: "string"
    },
    link_1_name: {
      title: "Link 1 name",
      validation: {
        required: false
      },
      dataType: "string"
    },
    link_2_url: {
      title: "Link 2 url",
      validation: {
        required: false
      },
      dataType: "string"
    },
    link_2_name: {
      title: "Link 2 name",
      validation: {
        required: false
      },
      dataType: "string"
    },
    background_img: buildProperty({
      title: "Background Card Image",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images",
          acceptedFiles: ["image/*"],
          storeUrl: true,
        }
      }
    }),
    interactive: ({
      values
    }) => buildProperty({
      title: "Interactive",
      dataType: "boolean",
      columnWidth: 100,
    }),
    show_card_notes: ({
      values
    }) => buildProperty({
      title: "Show card notes",
      dataType: "boolean",
      columnWidth: 100,
    }),
    audio: buildProperty({
      title: "Audio file",
      dataType: "string",
      columnWidth: 320,
      config: {
        storageMeta: {
          mediaType: "audio",
          storagePath: "audio",
          acceptedFiles: ["audio/*"],
          storeUrl: true,
        }
      }
    }),
    user: {
      dataType: "reference",
      title: "User",
      description: "Reference to creator of the card",
      path: "users"
    },
  }
});


export default function App() {

  const navigation: NavigationBuilder = async ({
    user,
    authController
  }: NavigationBuilderProps) => {

    return ({
      collections: [
        buildCollection({
          path: "users",
          schema: userSchema,
          name: "users",
          defaultSize: "xs",
          initialSort: ["created_time", "desc"],
          permissions: ({
            authController
          }) => ({
            edit: true,
            create: true,
            
            delete: authController.extra.roles.includes("admin")
          })
        }),
        buildCollection({
          path: "categories",
          schema: categorySchema,
          name: "categories",
          defaultSize: "xs",
          permissions: ({
            authController
          }) => ({
            edit: true,
            create: true,
            
            delete: authController.extra.roles.includes("admin")
          })
        }),
        buildCollection({
          path: "sessions",
          schema: sessionSchema,
          name: "sessions",
          defaultSize: "xs",
          initialSort: ["created_on", "desc"],
          permissions: ({
            authController
          }) => ({
            edit: true,
            create: true,
            
            delete: authController.extra.roles.includes("admin")
          })
        }),
        buildCollection({
          path: "cards",
          schema: cardSchema,
          name: "cards",
          defaultSize: "xs",
          permissions: ({
            authController
          }) => ({
            edit: true,
            create: true,
            
            delete: authController.extra.roles.includes("admin")
          })
        }),
        buildCollection({
          path: "programs",
          schema: programSchema,
          name: "programs",
          defaultSize: "xs",
          initialSort: ["created_on", "desc"],
          permissions: ({
            authController
          }) => ({
            edit: true,
            create: true,
            
            delete: authController.extra.roles.includes("admin")
          })
        }),
        buildCollection({
          path: "languages",
          schema: languageSchema,
          name: "languages",
          defaultSize: "xs",
          permissions: ({
            authController
          }) => ({
            edit: true,
            create: true,
            
            delete: authController.extra.roles.includes("admin")
          })
        }),
        buildCollection({
          path: "faqs",
          schema: faqSchema,
          name: "faqs",
          defaultSize: "xs",
          permissions: ({
            authController
          }) => ({
            edit: true,
            create: true,
            
            delete: authController.extra.roles.includes("admin")
          })
        }),
        buildCollection({
          path: "reviews",
          schema: reviewSchema,
          name: "reviews",
          defaultSize: "xs",
          permissions: ({
            authController
          }) => ({
            edit: true,
            create: true,
            
            delete: authController.extra.roles.includes("admin")
          })
        }),
        buildCollection({
          path: "collaborators",
          schema: collaboratorSchema,
          name: "collaborators",
          defaultSize: "xs",
          permissions: ({
            authController
          }) => ({
            edit: true,
            create: true,
            
            delete: authController.extra.roles.includes("admin")
          })
        })
      ]
    });
  };

  const myAuthenticator: Authenticator = async ({ user, authController }) => {
    console.log("Allowing access to", user?.email);
    // This is an example of retrieving async data related to the user
    // and storing it in the user extra field.
    if(user?.email === "bart@moijoy.com" || user?.email === "jelle@moijoy.com"){
      const sampleUserData = await Promise.resolve({
        roles: ["admin"]
    });
    authController.setExtra(sampleUserData);
      console.log("Allowed access");
      return true;

    }else{
      console.log("Not allowed access");
      return false;
    };
};

  return <FirebaseCMSApp
  name={"Moijoy CMS"}
  authentication={myAuthenticator}
  navigation={navigation}
  firebaseConfig={firebaseConfig}
/>;
}